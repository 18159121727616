/**
 * This guard ensures that route parameters are normalized to prevent issues with
 * URLs and route handling. In particular, it prevents the '[object Object]' issue
 * by converting any object parameters to string values.
 */
export default function paramNormalizer(to, from, next) {
  // console.log('paramNormalizer START - to.path:', to.path, 'from.path:', from.path);
  // console.log('paramNormalizer - to.params:', JSON.stringify(to.params), 'to.query:', JSON.stringify(to.query));
  
  // Nothing to normalize if no params exist
  if (!to.params || Object.keys(to.params).length === 0) {
    // console.log('paramNormalizer - No params to normalize');
    return next();
  }

  // Clone params to avoid mutating the original
  const normalizedParams = { ...to.params };
  let hasChanges = false;

  // Look specifically for communityId or communitySlug
  ['communityId', 'communitySlug'].forEach(param => {
    if (normalizedParams[param] !== undefined) {
      // console.log(`paramNormalizer - Processing ${param} - Original value:`, normalizedParams[param], 'Type:', typeof normalizedParams[param]);
      
      if (typeof normalizedParams[param] === 'object' && normalizedParams[param] !== null) {
        // console.log(`paramNormalizer - Converting object ${param}:`, JSON.stringify(normalizedParams[param]));
        // Extract slug or id from object, or use empty string
        const oldValue = normalizedParams[param];
        normalizedParams[param] = oldValue.slug || oldValue.id || '';
        // console.log(`paramNormalizer - Converted ${param} to:`, normalizedParams[param]);
        hasChanges = true;
      } else if (normalizedParams[param] && typeof normalizedParams[param] !== 'string') {
        // Convert any non-string value to string
        // console.log(`paramNormalizer - Converting non-string ${param}:`, normalizedParams[param]);
        normalizedParams[param] = String(normalizedParams[param]);
        // console.log(`paramNormalizer - Converted ${param} to:`, normalizedParams[param]);
        hasChanges = true;
      }
      
      // Additional validation for empty or invalid strings
      if (normalizedParams[param] === 'undefined' || normalizedParams[param] === 'null' || 
          normalizedParams[param] === 'error' || normalizedParams[param] === '[object Object]') {
        // console.log(`paramNormalizer - Detected invalid ${param} value:`, normalizedParams[param]);
        normalizedParams[param] = '';
        hasChanges = true;
      }
    }
  });

  // If any params changed, redirect to same route with normalized params
  if (hasChanges) {
    // console.log('paramNormalizer - Redirecting with normalized params', JSON.stringify(normalizedParams));
    return next({
      ...to,
      params: normalizedParams,
      // Preserve the query and hash
      query: to.query,
      hash: to.hash
    });
  }

  // No changes needed, continue normally
  // console.log('paramNormalizer END - No changes needed');
  return next();
} 