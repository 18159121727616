import { isUserLoggedIn, getNewAuthRoute } from '@/auth/utils';
import { canNavigate } from '@/libs/acl/routeProtection';
import { getDomain } from '@/store/auth/auth.store';
import Vue from 'vue';
import store from '@/store/index';
import i18n from '@/libs/i18n';
import { AccessTokenKey } from '@/store/auth/auth.store.constants';

// Enhanced function to check if user is truly logged in
// This ensures we don't have an expired or invalid token
const isUserTrulyLoggedIn = () => {
  try {
    // Check if the cookie exists
    const token = Vue.$cookies.get(AccessTokenKey);
    if (!token) {
      // If token doesn't exist in cookie but exists in localStorage, clean it up
      const localStorageToken = localStorage.getItem(AccessTokenKey);
      if (localStorageToken) {
        localStorage.removeItem(AccessTokenKey);
      }
      return false;
    }
    
    // Check if token is in localStorage (double verification)
    const localStorageToken = localStorage.getItem(AccessTokenKey);
    
    // If token in cookie but not in localStorage, sync it
    if (token && !localStorageToken) {
      localStorage.setItem(AccessTokenKey, token);
    }
    
    // Return true only if both cookie and localStorage have tokens
    return !!token && !!localStorageToken;
  } catch (e) {
    console.error('Error in isUserTrulyLoggedIn:', e);
    return false;
  }
};

const handleLanguageChange = (lang) => {
  i18n.locale = lang;
  Vue.$cookies.set('i18n_redirected', i18n.locale);
  store.dispatch('setLocale', i18n.locale);
};

const getRedirectUrl = (communityId) => {
  // Because of our paramNormalizer guard, communityId should always be a string or null/undefined by this point
  // But add simple safety check just in case
  // console.log('communityId passed to getRedirectUrl:', communityId);
  
  const validSlug = communityId ? String(communityId) : '';
  
  console.log(`Creating auth route with slug: '${validSlug}'`);
  const communityForAuthRoute = {
    slug: validSlug
  };

  return getNewAuthRoute(communityForAuthRoute);
};

const getCommunityId = (params) => {
  // console.log('getCommunityId - params:', params);
  const id = params.communitySlug || params.communityId;
  // console.log('getCommunityId - raw id:', id, 'type:', typeof id);
  
  if (!id) {
    // console.log('getCommunityId - id is null or undefined');
    return null;
  }
  
  // Handle object id
  if (typeof id === 'object' && id !== null) {
    // console.log('getCommunityId - id is object:', JSON.stringify(id));
    return id.slug || id.id || null;
  }
  
  // console.log('getCommunityId - returning string id:', String(id));
  return String(id);
};

export default async function authGuard(to, _, next) {
  try {
    // Check if this is a Nexos customer on the root path
    const isNexos = process.env.VUE_APP_CUSTOMER === 'nexos';
    
    // Special handling for root path - allow access without authentication
    if (to.path === '/' || to.name === 'root-welcome') {
      console.log('Auth guard - allowing access to root welcome page');
      return next();
    }
    
    // console.log('authGuard - to:', to.fullPath, 'params:', JSON.stringify(to.params));
    const communityId = getCommunityId(to.params);
    // console.log('authGuard - communityId:', communityId, 'type:', typeof communityId);

    // if communityId is null
    if (!communityId) {
      // console.log('authGuard - communityId is null, checking if route is already an auth route');
      
      // Check if we're already on an auth route to prevent redirect loops
      const isAlreadyAuthRoute = to.path.includes('/auth/') || to.name?.startsWith('auth-');
      if (isAlreadyAuthRoute) {
        // console.log('Already on auth route, continuing normally');
        return next();
      }
      
      // Determine the appropriate redirect URL
      let redirectUrl;
      
      if (isNexos && process.env.VUE_APP_URL && process.env.VUE_APP_MAIN_SPACE_SLUG) {
        redirectUrl = `${process.env.VUE_APP_URL}/${process.env.VUE_APP_MAIN_SPACE_SLUG}/auth/login`;
      } else if (process.env.VUE_APP_MY_NECTIOS_URL) {
        redirectUrl = `${process.env.VUE_APP_MY_NECTIOS_URL}/login`;
      } else {
        // Fallback to a relative redirect if environment variables aren't set
        // console.log('Environment variables not set, using relative redirect');
        return next({ name: 'auth-login' });
      }
      
      // console.log(`Redirecting to ${redirectUrl}`);
      window.location.href = redirectUrl;
      return;
    }

    // Check if user is truly logged in with enhanced check
    let isLoggedIn = false;
    try {
      isLoggedIn = isUserTrulyLoggedIn();
    } catch (e) {
      console.error('Error checking login status:', e);
      isLoggedIn = false;
    }

    // console.log('Auth guard - User logged in:', isLoggedIn, 'Route:', to.path, 'Meta:', to.meta);

    // Handle redirect for auth routes (like login, signup) if user is already logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      // console.log('Redirecting logged in user from auth route to main page');
      // Enhanced validation to ensure we have a valid string slug
      let validSlug = '';
      
      if (communityId) {
        if (typeof communityId === 'object' && communityId !== null) {
          validSlug = communityId.slug || '';
        } else {
          validSlug = String(communityId);
        }
      }
      
      return next({
        name: 'Resumen',
        params: { communityId: validSlug },
      });
    }

    const { jwttoken, lang } = to.query;

    // Handle JWT token authentication
    if (!isLoggedIn && jwttoken) {
      if (lang) {
        handleLanguageChange(lang);
      }
      await store.dispatch('secureAuth', { token: jwttoken });
      return next();
    }

    // Handle navigation permissions
    if (!canNavigate(to)) {
      if (!isLoggedIn) {
        // Fix: Store only the URL string rather than the entire window.location object
        const urlString = window.location.href;
        // // console.log('Setting lastUrl cookie to string value:', urlString);
        // Vue.$cookies.set('lastUrl', urlString, '1d', '/', getDomain(), true, 'Strict');
        
        // get the new auth route (if communityId is null, we go to my-nectios)
        const newAuthRoute = getRedirectUrl(communityId);
        // console.log('Redirecting to auth route:', newAuthRoute);
        
        // redirect to the new auth route
        window.location.href = newAuthRoute;
        return;
      }
      return next({ name: 'misc-not-authorized' });
    }

    return next();
  } catch (error) {
    console.error('Auth guard error:', error);
    return next({ name: 'error-500' });
  }
}
