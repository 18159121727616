import Vue from 'vue';
import VueRouter from 'vue-router';
import myNectiosRoutes from './routes/mynectios-routes';
import spaceRoutes from './routes/space-routes';
import backofficeRoutes from './routes/backoffice-routes';
import myselfRoutes from './routes/myself-routes';
import authRoutes from './routes/auth-routes';
import legalRoutes from './routes/legal-routes';
import authGuard from './guards/auth-guard';
import paramNormalizer from './guards/param-normalizer';
import errorRoutes from './routes/error-routes';
// import { canNavigate } from '@/libs/acl/routeProtection';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // savedPosition is only available for popstate navigations.
    if (savedPosition) return savedPosition;

    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    if (to.params.savePosition) return {};

    // scroll to anchor by returning the selector
    if (to.hash) {
      const position = { selector: to.hash };

      // specify offset of the element
      // if (to.hash === '#anchor2') {
      //   position.offset = { y: 100 }
      // }
      return position;
    }

    // scroll to top by default
    return { x: 0, y: 0 };
  },
  routes: [
    // Root welcome route
    {
      path: '/',
      name: 'root-welcome',
      component: () => import('@/views/WelcomeToNectios.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
        requiresAuth: false,
      },
    },
    // TODO: delete:
    // MY NECTIOS (AUTH)
    ...myNectiosRoutes,
    // COMMUNITY (AUTH) [deprecated]
    ...authRoutes,
    ...legalRoutes,
    // QR ROUTE
    {
      path: '/QR/:communityId/:username',
      name: 'qr',
      component: () => import("@/views/qr/QRPage.vue" /* webpackChunkName: "QRPage" */),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/:communityId",
      component: () => import("@/views/MainRoot.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        ...errorRoutes,
        // BACKOFFICE - The place where the staff administrate the space
        ...backofficeRoutes,
        // MYSELF - Member Account
        ...myselfRoutes,
        // FRONTOFFICE - Space: The place where members interact
        ...spaceRoutes,
        // TODO: Create welcomeError page for users who make a mistake in the url, warning of their error
        {
          path: "*",
          redirect: "/:communityId/welcome",
          meta: {},
        },
      ],
    },
    // ERROR ROUTE
    {
      path: '*',
      redirect: 'error',
    },
  ],
});

// Apply the parameter normalizer guard first - this ensures all route params are properly formatted
router.beforeEach(paramNormalizer);

// Add a guard to detect and fix [object Object] in the URL
router.beforeEach((to, from, next) => {
  // console.log('URL Object Check - to.path:', to.path);

  // Check if the URL contains [object Object]
  if (to.path.includes('%5Bobject%20Object%5D') || to.path.includes('[object Object]')) {
    console.error('CRITICAL: URL contains [object Object] - Path:', to.path);

    // Extract what we can from the path
    const segments = to.path.split('/');
    const validSegments = segments.filter((segment) => !segment.includes('%5Bobject%20Object%5D')
      && !segment.includes('[object Object]')
      && segment.trim() !== '');

    // If we have a valid first segment, use it as communityId
    const communityId = validSegments.length > 0 ? validSegments[0] : '';
    // console.log('URL Object Check - extracted communityId:', communityId);

    // Redirect to a safe path
    if (communityId) {
      return next({
        path: `/${communityId}`,
        query: to.query,
        hash: to.hash,
      });
    }
    // If no valid path segments, redirect to root
    return next('/');
  }

  return next();
});

// Apply the auth guard after parameter normalization
router.beforeEach(authGuard);

export default router;
